import { useQuery } from 'react-query';
import LinkTo from '../components/atoms/Link/LinkTo';
import SubscriptionButton from '../components/molecules/Subscriptios/SubscriptionButton';
import GET_MY_TEAM_PLANS_BY_PARAMS_GQL from '../graphql/queries/getMyTeamPlansByParamsGql';
import Theme from '../styles/Theme';
import useCsvNumber from './useCsvNumber';
import useFormatDate from './useDate';
import useProcessData from './useProcessData';
import useNumberFormat from './useNumberFormat';
import useSummaryTeamColumns from './useSummaryTeamColumns';
import { getGraphQLClient } from '../graphql/graphqlClient';

type Variables = {
  [key: string]: string | number | undefined;
  timeframe?: string;
  startDate?: string;
  endDate?: string;
  grouping?: string;
  type?: string;
  status?: string;
  year?: number;
}

export default function useSummaryTeamByPlan(
  variables: Variables,
  userId: string,
) {
  const graphQLClient = getGraphQLClient();
  let planStructure = [];
  const planType = variables.type;

  const {
    columns,
    columnsExcel,
  } = useSummaryTeamColumns(planType);

  const {
    data,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`${planType}_summary`, variables],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      variables,
    )),
    {
      staleTime: Infinity,
    },
  );

  planStructure = data?.myTeamPlans?.nodes?.map((plan: any) => ({
    name: plan?.planDescription,
    participants: useNumberFormat(plan?.participantsNumber),
    updatedAt: useFormatDate(plan?.planDataDate),
    status: plan?.planStatusName,
    wins: useNumberFormat(plan?.winsNumber),
    amount: useNumberFormat(plan?.resultsAmount, true),
    comply: plan?.fulfillsNumber,
    dataDate: useFormatDate(plan?.planDataDate),
    results: <LinkTo to={`/team/convention/${plan?.planCode}`} color={Theme.colorPrimary}>Ver resultados</LinkTo>,
    subscribed: <SubscriptionButton
      subscribed={plan.planSubscribed}
      planId={plan.id}
      userId={userId}
    />,
  }));

  const planFormatExcel = planStructure !== undefined
    ? planStructure?.map((d: any) => ({ ...d }))
    : [];

  planFormatExcel.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      planFormatExcel[index][key] = useCsvNumber(value);
    });
  });

  const planFormat = useProcessData(planStructure);

  return {
    planType,
    planFormat,
    planFormatExcel,
    refetch,
    isFetching,
    error,
    isLoading,
    columns,
    columnsExcel,
  };
}
