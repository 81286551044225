import { gql } from 'graphql-request';

const GET_TERRITORY_NIF_USERS_GQL = gql`
query MyQuery($dgt: String, $dt: String, $od: String, $year: Int, $planType: String, $planStatus: String) {
  dropdownTerritoriesNifUsers(dgt: $dgt, dt: $dt, od: $od, year: $year, planType: $planType, planStatus: $planStatus) {
    arrayUsersData
  }
}
`;
export default GET_TERRITORY_NIF_USERS_GQL;
