import LinkTo from '../components/atoms/Link/LinkTo';
import SubscriptionButton from '../components/molecules/Subscriptios/SubscriptionButton';
import { IPlan, IResult } from '../interfaces/IPlan.d';
import Theme from '../styles/Theme';
import useFormatDate from './useDate';
import useNumberFormat from './useNumberFormat';
import { LinkTable } from '../components/atoms/Table/Table';
import IconArrow from '../components/atoms/Icon/IconArrow';

export const columnsDetails = [
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }:any) => (
      <label {...row.getToggleRowExpandedProps()}>
        <LinkTable title="Detalle" onClick={() => '#'}>
          {row.isExpanded
            ? <IconArrow color={`${Theme.colorPrimary}`} rotate />
            : <IconArrow color={`${Theme.colorPrimary}`} /> }
        </LinkTable>
      </label>
    ),
  },
  {
    Header: 'Detalle',
    accessor: 'detail',
  },
];

export default function useGlobalPosition(data: any) {
  const situation = (plan:any) => {
    const tr = plan ? JSON.parse(plan?.translations) : {};
    const objSituation = plan?.results?.nodes?.map((obj: any) => obj?.extraFields);

    const result = Object.keys(objSituation)
      ?.map((_: any, index: number) => Object.entries(objSituation[index])
        .filter(([key, value]: any) => tr[key]?.toLowerCase() === 'situación' && typeof value === 'string'));

    return result?.[0]?.[0]?.[1];
  };

  const userId = data?.me?.id;

  let getPlan = data?.me?.plans?.nodes?.map((plan: IPlan) => ({
    id: plan?.id,
    plan: plan?.description,
    comply: +useNumberFormat(plan?.fulfillsNumber) > 0 ? 'SI' : 'NO',
    winsNumber: +useNumberFormat(plan?.winsNumber) > 0 ? 'SI' : 'NO',
    amount: useNumberFormat(plan?.resultsAmount),
    situation: situation(plan),
    startDate: plan?.startDate !== undefined && useFormatDate(plan?.startDate),
    endDate: plan?.endDate !== undefined && useFormatDate(plan?.endDate),
    updatedAt: plan?.updatedAt,
    results: plan?.results,
    planLevels: plan?.planLevels,
    dataDate: plan?.dataDate !== undefined && useFormatDate(plan?.dataDate),
    updateDate: plan?.updateDate,
    grouping: plan?.grouping,
    translations: plan?.translations,
    comparative: <LinkTo to={`/global-position/${plan.type === 'Campaign' ? 'campaign' : 'rappel'}/${plan?.code}`} color={Theme.colorPrimary}>Ver comparativa</LinkTo>,
    resultId: plan?.results?.nodes[0]?.id,
    groupedData: plan?.groupedData,
    groupedItems: plan?.groupedItems,
    type: plan?.type,
    planCode: plan?.code,
    status: plan?.status,
    planStatusName: plan?.planStatusName,
    subscribed: <SubscriptionButton
      subscribed={plan.subscribed}
      planId={plan.id}
      userId={userId}
    />,
    download: <LinkTable url={plan?.basesUrl} title="Descargar" />,
  }));

  // eslint-disable-next-line array-callback-return
  getPlan = getPlan?.map((plan: any) => {
    const levelsCount = plan?.planLevels?.map(
      (e:any) => e.level,
    ).sort((a:any, b:any) => b - a)[0];
    if (levelsCount > 1) {
      const result = plan?.results?.nodes?.find((e:IResult) => e.id === plan?.resultId);
      // eslint-disable-next-line no-param-reassign
      plan.detail = <LinkTo to={`/global-position/${plan?.type?.toLowerCase()}/${plan.resultId}/detail/${result?.firstName} ${result?.lastName}?code=${plan.planCode}&from=mpg`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver detalle</LinkTo>;
    }
    return plan;
  });

  return { getPlan };
}
