import { gql } from 'graphql-request';

const SUBSCRIBED_OR_UNSUBSCRIBED_TO_PLAN = gql`
  mutation subscribeOrUnsubscribeToPlan($userId: ID!, $planId: ID!, $subscribe: Boolean!, $from: String!) {
    subscribeOrUnsubscribeToPlan(userId: $userId, planId: $planId, subscribe: $subscribe, from: $from) {
      id
    }
  }
`;

export default SUBSCRIBED_OR_UNSUBSCRIBED_TO_PLAN;
